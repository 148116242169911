import React from "react";
import PageView from "../../../components/views/table-view";
import { useSkills } from "../../../hooks/useSkills";
import { useReporting } from "../../../hooks/useReporting";

const Reporting = () => {
  const { isLoading, reports, permission, deleteReport } = useReporting();

  // console.log({ reports });

  return (
    <PageView
      data={reports}
      canCreate={permission?.create}
      isLoading={isLoading}
      rowHasUpdate={permission?.update}
      rowHasDelete={permission?.delete}
      onDelete={deleteReport}
      columns={[
        {
          Header: "id",
          accessor: "id",
        },
        {
          Header: "Name",
          accessor: "report_type",
        },
      ]}
    />
  );
};

export default Reporting;
